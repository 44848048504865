.copyright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #b6b6b6;
    padding-top: 20px;
    padding-bottom: 20px;
}

.copyright .fa-heart {
    color: red;
}

.copyright ul {
    flex: 1;
    text-align: center;
    padding-bottom: 15px;
}

.copyright ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 25px;
}

.copyright ul li a {
    color: white;
    text-decoration: none;
    font-size: 40px;
}

.copyright ul li a:hover {
    color: var(--blue-primary);
}

.copyright .topP {
    padding-bottom: 5px;
}

.copyright-social-links {
    text-align: center;
    padding: 0;
}

@media (max-width: 790px) {

    .copyright-social-links {
        text-align: center;
        padding: 0;
    }

}