
::selection {
    color: gray;
    background: #3ea0e2;
}

.resume {
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    margin: 120px 0;
}

.resume h2 {

    text-align: center;
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 30px;

}


.wrapper {
    padding: 5px;
    position: relative;
}

.wrapper .center-line-experiences {
    position: absolute;
    height: 86%;
    width: 4px;
    background: gray;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.wrapper .center-line-education {
    position: absolute;
    height: 50%;
    width: 4px;
    background: gray;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.wrapper .center-line-skills {
    position: absolute;
    height: 60%;
    width: 4px;
    background: gray;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.wrapper .row {
    display: flex;
    padding-bottom: 20px;
}

.wrapper .row-1 {
    justify-content: flex-start;
    padding: 0 10px 20px 10px;
}

.wrapper .row-2 {
    justify-content: flex-end;
    padding: 0 10px 20px 10px;
}

.wrapper .row section {
    background: #fff;
    border-radius: 5px;
    width: calc(50% - 40px);
    position: relative;
}

.wrapper .row section::before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    top: 28px;
    z-index: -1;
    transform: rotate(45deg);
}

.row-1 section::before {
    right: -7px;
}

.row-2 section::before {
    left: -7px;
}

.row section .icon {
    position: absolute;
    background: #f2f2f2;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: var(--blue-primary);
    font-size: 17px;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.row-1 section .icon {
    top: 15px;
    right: -60px;
}

.row-2 section .icon {
    top: 15px;
    left: -60px;
}

.row section .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row section .details .title {
    font-size: 22px;
    font-weight: 600;
}

.row section .details .title, .dates {
    padding: 5px;
}

.row section p, li {
    color: var(--dark-gray);
}

.row section ul {
    margin: 10px 0 17px 0;
    color: #535353;
}

.row section ul li {
    padding: 5px;
}

@media (max-width: 790px) {
    .wrapper .center-line-experiences {
        left: 35px;
        top: 50px;
        height: 85%;
    }

    .wrapper .center-line-education {
        left: 35px;
        top: 50px;
    }

    .wrapper .center-line-skills {
        left: 35px;
        top: 50px;
        height: 60%;
    }

    .wrapper .row {
        margin: 30px 0 3px 60px;
    }

    .wrapper .row section {
        width: 100%;
    }

    .row-1 section::before {
        left: -7px;
    }

    .row section .details .title, .dates {
        padding: 5px;
    }

    .row-1 section .icon {
        left: -60px;
    }
}

@media (max-width: 440px) {
    .wrapper .center-line-experiences,
    .wrapper .center-line-education,
    .wrapper .center-line-skills,
    .row section::before,
    .row section .icon {
        display: none;
    }

    .wrapper .row {
        margin: 10px 0;
    }

    .row section .details .title, .dates {
        padding: 5px;
    }

}
