:root {
  --baby-blue: #00cccc; /* baby blue */
  --gray: gray; /* gray */
  --dark-gray: #4F4E4EFF; /* dark gray */
  --white: white; /* white */
  --black: black; /* black */
  --blue-primary: #95d8e8;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fredoka', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
