.projects {
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    margin: 80px 0;
}

.projects h2 {
    color: #535353;
    font-weight: 500;
    font-size: 30px;
    margin: 60px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projects .cardRow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.projects .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    max-width: 300px;
    margin: 20px;
    text-align: center;
    padding: 40px;
}

.projects h3, p {
    padding: 5px;
}

.projects .card-img {
    max-height: 200px;
}

.projects .fancyFont {
    color: var(--gray);
    padding-bottom: 35px;
}

.projects .goToProject {
    background: var(--blue-primary);
    color: white;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 30px;
    transition: transform 0.5s;
}

.projects .goToProject:hover {
    transform: scale(0.9);
}

