.hero-homepage {
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('../../assets/img/hero-image-2.png');
    height: 100vh;
    width: 100%;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
}

.hero-projects {
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('../../assets/img/hero-image-1.png');
    height: 100vh;
    width: 100%;
    background-size: cover;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
}

.content {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.content h1 {
    font-size: 70px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.content h2 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 790px) {

    .content h1 {
        text-align: center;
        font-size: 50px;
    }

    .content h2 {
        text-align: center;
        font-size: 30px;
    }

    .navbarLinks {
        text-align: center;
        padding: 0;
    }

}