.logo {
    width: 110px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 25px;
    font-size: 30px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

nav ul li a:hover {
    color: var(--blue-primary);
}

nav ul li a i {
    display: none;
    opacity: 0;
}

@media (max-width: 790px) {

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    nav ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        text-align: center;
    }

}