.about {
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    margin: 120px 0;
}

.about-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
}

.about .about-header-content .about-h2 {
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 40px;
    margin: 40px 0;
    text-align: center;
}

.about .about-header-content i {
    color: var(--blue-primary);
    padding: 5px;
}

.about .about-header-content .AK-Img {
    max-height: 400px;
    max-width: 300px;
    height: auto;
    width: auto;
    border-radius: 25px;
}

.about p{
    color: #535353;
    font-size: 17px;
    line-height: 30px;
}

.about p span {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

@media (max-width: 790px) {

    .about .about-header-content .about-h2 {
        font-size: 25px;
    }

    .about .about-header-content .AK-Img {
        max-height: 200px;
        max-width: 150px;
        height: auto;
        width: auto;
        border-radius: 25px;
    }

}